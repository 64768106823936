import { default as Vue, VNode } from "vue";
import Phaser from "phaser";
import designPagesToJson from "@/types/screen/edit-mode/designPagesToJson";
interface IAction {
  commit: Function;
  dispatch: Function;
  getters: any;
  rootGetters: string[];
  rootState: any;
  state: IScreen;
}

interface IScreenPage {
  pageNumber: number;
  design: VNode;
  code: string;
}

interface IScreen {
  os: string;
  showingPage: number;
  pages: IScreenPage[];
  sounds: any[];
  uniqueIdCounter: Map<string, number>;
}

export default {
  namespaced: false,
  state: {
    os: "iOS",
    showingPage: null,
    pages: [],
    sounds: [],
    uniqueIdCounter: new Map(),
  },
  getters: {
    allDesignPages(state: IScreen) {
      return state.pages;
    },
    showingScreenPage(state: IScreen) {
      return state.showingPage;
    },
    screenPageLength(state) {
      return state.pages.length;
    },
    showingDesignPage(state: IScreen, getters) {
      const wantedPage = getters.showingScreenPage;
      const foundPage = state.pages.find(
        (page) => page.pageNumber === wantedPage
      );
      return foundPage?.design;
    },
    screenOs(state: IScreen) {
      return state.os;
    },
    isScreenOsIos(state: IScreen, getters) {
      return getters.screenOs === "iOS";
    },
    isScreenOsAndroid(state: IScreen, getters) {
      return getters.screenOs === "ANDROID";
    },
    appJson(state: IScreen, getters: any, rootState: any, rootGetters: any) {
      const ASSET_URL =
        process.env.VUE_APP_MODE === "development"
          ? window.origin
          : rootGetters["CodementumUrl/gamemaker"];

      const screen = document.querySelector(".view-phone-container");
      const canvas = screen.querySelector("canvas");

      return {
        os: state.os,
        sounds: state.sounds,
        pages: designPagesToJson(state.pages, ASSET_URL),
        uniqueIdCounter: [...state.uniqueIdCounter],
        ShowingScreenPage: state.showingPage,
        screenSize: [
          // We are sending -1 here
          // Because of the css constrains.
          (canvas.width || screen.clientWidth) - 1,
          (canvas.height || screen.clientHeight) - 1,
        ],
      };
    },
    soundsList(state: IScreen) {
      return state.sounds;
    },
  },
  mutations: {
    setScreenOs(state, os) {
      state.os = os;
    },
    changeShowingScreenPage(state: IScreen, n) {
      if (!n) return;

      state.showingPage = n;
    },
    addScreenPage(state, n) {
      state.pages.push({
        pageNumber: n,
        design: {},
        code: "",
      });
    },
    removeScreenPage(state, n) {
      const index = state.pages.findIndex((p) => p.pageNumber === n);
      if (index !== -1) Vue.delete(state.pages, index);
    },
    setPageCode(state, { pageNumber, code }) {
      const page = state.pages.find((page) => page.pageNumber === pageNumber);
      page.code = code;
    },
    setPages(state, pages) {
      state.pages = pages.map((page) => ({
        pageNumber: page.pageNumber,
        code: page.code,
        design: Object.assign({}, page.design),
      }));
    },
    setSounds(state, sounds) {
      state.sounds = [...state.sounds, ...sounds];
    },
    setUniqueIdCounter(state, uniqueIdCounter) {
      state.uniqueIdCounter = new Map(uniqueIdCounter);
    },
    removeSound(state, sound) {
      const removeIndex = state.sounds.findIndex((s) => s.id === sound.id);
      if (removeIndex === -1) return;
      state.sounds.splice(removeIndex, 1);
    },
  },
  actions: {
    createNewScreenPage(action: IAction, n) {
      const { commit, state } = action;
      if (state.pages.length > 5) return;
      /*
      find empty pageNumber*/
      n = state.pages.reduce((n, page) => {
        if (page && page.pageNumber < n) return n;
        if (page && page.pageNumber === n) return n + 1;
        return n;
      }, n);

      /* last page +1
      n =
        state.pages.sort((p1, p2) => p2.pageNumber - p1.pageNumber)[0]
          .pageNumber + 1;*/
      commit("addScreenPage", n);
      commit("changeShowingScreenPage", n);
    },
    removeExistScreenPage(action: IAction, pageNumber) {
      const { state, commit, dispatch } = action;
      const totalPage = state.pages.length;
      const currentScreenPageIndex = state.pages.findIndex(
        (page) => page.pageNumber === pageNumber
      );
      commit("removeScreenPage", pageNumber);

      if (currentScreenPageIndex < 1 && totalPage === 1) {
        dispatch("createNewScreenPage", 1);
      } else {
        const otherScreenPageNumber =
          state.pages.length === currentScreenPageIndex
            ? state.pages[currentScreenPageIndex - 1]
            : state.pages[currentScreenPageIndex];

        commit("changeShowingScreenPage", otherScreenPageNumber.pageNumber);
      }
    },
    registerDesignElement(
      action: IAction,
      payload: {
        targetId: string;
        gameObject:
          | Phaser.GameObjects.Container
          | Phaser.GameObjects.Sprite
          | Phaser.GameObjects.TileSprite;
      }
    ) {
      const { getters } = action;
      Vue.set(getters.showingDesignPage, payload.targetId, payload.gameObject);
    },
    registerSound(action: IAction, sound) {
      const { commit, state } = action;
      const foundSound = state.sounds.find((s) => s.id === sound.id);
      if (foundSound) return;
      commit("setSounds", [sound]);
    },
    /**
     * Find vnode path by targetId then delete vnode using path.reduce itareting path by path
     * lastly delete vnode using Vue.delete because of reactivity
     *
     * @param action vuex actions
     * @param targetId String
     */
    deleteDesignElement(action: IAction, targetId: string) {
      const { getters } = action;
      if (getters.showingDesignPage[targetId].destroy)
        getters.showingDesignPage[targetId].destroy();
      Vue.delete(getters.showingDesignPage, targetId);
    },
    saveScreenPageCode({ commit, getters, rootState }) {
      commit("setPageCode", {
        pageNumber: getters.showingScreenPage,
        code: rootState.workspace.session.designAndCoding.coding.code,
      });
    },
    syncAppJsonWithTutorialState(action) {
      const { commit, rootState } = action;
      if (rootState.workspace.session.tutorial.tutorialState.params) {
        let appJson = JSON.parse(
          rootState.workspace.session.tutorial.tutorialState.params
        );
        if (appJson) {
          if (appJson.os) commit("setScreenOs", appJson.os);
          if (appJson.sounds) commit("setSounds", appJson.sounds);
          if (appJson.uniqueIdCounter)
            commit("setUniqueIdCounter", appJson.uniqueIdCounter);
          if (appJson.ShowingScreenPage)
            commit("changeShowingScreenPage", appJson.ShowingScreenPage);
          if (appJson.pages && appJson.pages.length > 0) {
            commit("setPages", appJson.pages);
            // @todo service plugin
          }
        }
      }
    },
    getUniqueIdForDesignElement(action, name: string) {
      const { state }: { state: IScreen } = action;
      if (state.uniqueIdCounter.has(name) === false) {
        state.uniqueIdCounter.set(name, 1);
        return name + "-" + 1;
      } else {
        state.uniqueIdCounter.set(name, state.uniqueIdCounter.get(name) + 1);
        return name + "-" + state.uniqueIdCounter.get(name);
      }
    },
  },
};
