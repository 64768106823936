<script>
import main from "commonsfrontend/src/maker/vue-files/main";
export default {
  extends: main,
  metaInfo: {
    title: "GameMaker",
    titleTemplate: "%s - Codementum"
  }
};
</script>
