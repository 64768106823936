import session from "commonsfrontend/src/maker/store/session/index";
import screen from "./screen";

const sessionModified = {
  ...session,
  modules: {
    ...session.modules,
    screen
  }
};

export default sessionModified;
