// convert pages to json

export default (pages, ASSET_URL = "http://localhost:8080/") =>
  pages.map(page => {
    const design = Object.keys(page.design);
    return {
      ...page,
      design: design.reduce((o, key) => {
        o[key] = {
          lastSession: true,
          index: page.design[key].index
            ? page.design[key].index
            : page.design[key].getData("index"),
          // disable scale because of float number not appropriate exactly
          // scale: page.design[key].scale,
          designElement: page.design[key].designElement
            ? page.design[key].designElement
            : page.design[key].getData("designElement"),
          x: page.design[key].x,
          y: page.design[key].y,
          depth: page.design[key].depth,
          displayWidth: page.design[key].displayWidth,
          displayHeight: page.design[key].displayHeight,
          alpha: page.design[key].alpha,
          angle: page.design[key].angle,
          flipX: page.design[key].flipX === true ? "x" : undefined,
          flipY: page.design[key].flipY === true ? "y" : undefined,
          values: page.design[key].data
            ? page.design[key].data.values
            : page.design[key].values
        };

        if (page.design[key].getData) {
          o[key] = {
            ...o[key],
            imgSrc: new URL(page.design[key].getData("imgSrc"), ASSET_URL).href,
            tint: page.design[key].getData("tint"),
            anims: page.design[key].getData("anims"),
            tileX: page.design[key].getData("tileX"),
            tileY: page.design[key].getData("tileY"),
            fps: page.design[key].getData("fps"),
            list: page.design[key].getData("list")
          };
        }

        if (page.design[key].type === "Container") {
          if (
            page.design[key].list &&
            page.design[key].list[0].type === "Text"
          ) {
            o[key].style = {
              ...(({
                color,
                backgroundColor,
                align,
                fontFamily,
                fontSize
              }) => ({
                color,
                backgroundColor,
                align,
                fontFamily,
                fontSize
              }))(page.design[key].list[0].style)
            };
            if (page.design[key].content)
              o[key].text = page.design[key].content();
          }
        } else if (
          (page.design[key].type === "colorgrid" ||
            page.design[key].type === "imagepuzzle") &&
          page.design[key].getData
        ) {
          o[key].row = page.design[key].getData("row");
          o[key].col = page.design[key].getData("col");
        }

        return o;
      }, {})
    };
  });
