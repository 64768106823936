// External imports
/*
import "core-js/es6/promise";
import "core-js/es6/string";
import "core-js/es7/array";*/
import Vue from "vue";
import VueRouter from "vue-router";
import BootstrapVue from "bootstrap-vue";
import axios from "axios";
import {
  CodementumNavbarPlugin,
  userStateModule,
  Loading as CodementumLoader
} from "commonsfrontend";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import asyncBlockDirective from "commonsfrontend/src/shared/vue/asyncBlockDirective.js";

// Internal imports
import MainComponent from "./main.vue";
import router from "./router";
import i18n from "./i18n/all";
import store from "@/store/index.js";
import VuexVueEvents from "@/store/events.js";
import VueEvents from "@/events.js";
import "./icons.js";

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(CodementumLoader); // #Ref-2
Vue.use(CodementumNavbarPlugin, {
  store,
  axios,
  storeMerge: {
    modules: {
      state: userStateModule() // @param Special axios instance
    }
  },
  i18n,
  mode: process.env.VUE_APP_MODE,
  pkgVersion: process.env.PKG_VERSION
});

Vue.component("font-awesome-icon", FontAwesomeIcon);

// General event bus.
Vue.prototype.$eventBus = new Vue();

Vue.use(asyncBlockDirective);

// Create vue instance
new Vue({
  beforeCreate() {
    VuexVueEvents(store, this.$root);
    VueEvents(this.$root);
  },
  data() {
    return {
      settings: {
        designElementsOrder: {
          // component-name: "title"
          backgrounds: "Backgrounds",
          assets: "Assets",
          components: "Components",
          sounds: "Sounds"
        }
      }
    };
  },
  router,
  // @ts-ignore
  store,
  render: h => h(MainComponent)
}).$mount("#gamemaker");
