import tutorial from "commonsfrontend/src/maker/store/tutorial/index";
import session from "./session/index";

export default {
  namespaced: true,
  modules: { tutorial, session },
  getters: {
    mainApi(state, getters, rootState, rootGetters) {
      return rootGetters["CodementumUrl/gamemakerApi"];
    }
  }
};
